import { type FC, useState, useEffect } from 'react'
import { type Validate, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'

import style from './style.module.scss'
import { Button, FormItem, FormHeader } from '../../components'
import { removeItem, getItem } from '../../utils/localstorage'
import { type PageFormData } from '../administrator'
import { createApplication } from '../../api'
import { defaultInputValidator } from '../../utils/formValidator'

export interface ObjectsProps {}

interface ObjectFormData {
  objectName: string
  postalCode: string
  prefecture: string
  cityTownVillage: string
  streetAddress: string
  numberOfUnits: string
  managementCompanyName: string
  managementCompanyNameOfContactPerson: string
  managementCompanyContact: string
  numberOfAutoLockIntercomUnits: string
  wishToAddDeliveryBox: string
}

const generateFormDefaultValues = (): ObjectFormData => {
  return {
    objectName: '',
    postalCode: '',
    prefecture: '',
    cityTownVillage: '',
    streetAddress: '',
    numberOfUnits: '',
    managementCompanyName: '',
    managementCompanyNameOfContactPerson: '',
    managementCompanyContact: '',
    numberOfAutoLockIntercomUnits: '',
    wishToAddDeliveryBox: ''
  }
}

const prefecture = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
]

const formatTopFormData = (data: PageFormData['topForm']) => {
  return {
    郵便番号: data.postalCode,
    住所: data.address,
    法人名: data.nameOfCorporation,
    責任者名: data.responsiblePersonName,
    役職者名: data.titleHolderName,
    部門部署名: data.departmentSignature,
    電話番号: data.phoneNumber,
    メールアドレス: data.emailAddress,
    アイホンから直接説明を受けた: data.directlyByAiphone === '1'
  }
}
const formatBottomFormData = (data: PageFormData['topForm']) => ({
  郵便番号: data.postalCode,
  住所: data.address,
  法人名: data.nameOfCorporation,
  氏名: data.responsiblePersonName,
  部門部署名: data.departmentSignature,
  電話番号: data.phoneNumber,
  メールアドレス: data.emailAddress
})
const formatObjectData = (data: ObjectFormData) => {
  return {
    物件名称: data.objectName,
    郵便番号: data.postalCode,
    都道府県: data.prefecture,
    市区町村: data.cityTownVillage,
    町域番地: data.streetAddress,
    戸数: data.numberOfUnits,
    管理会社名: data.managementCompanyName,
    管理会社担当者名: data.managementCompanyNameOfContactPerson,
    管理会社連絡先: data.managementCompanyContact,
    エントランスインターホン台数: data.numberOfAutoLockIntercomUnits,
    宅配ボックス増設希望有無: data.wishToAddDeliveryBox === '1'
  }
}

export const ObjectsPage: FC<ObjectsProps> = () => {
  const [defaultValues, setDefaultValues] = useState<Record<string, ObjectFormData>>({
    [uuid()]: generateFormDefaultValues()
  })

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const { topForm, bottomFormMap } = getItem('administrator') as PageFormData || {}

    if (!topForm || !bottomFormMap) {
      navigate('/administrator', { replace: true })
    }
  }, [])

  const {
    register,
    formState: { errors },
    handleSubmit,
    unregister,
    trigger,
    getValues
  } = useForm<Record<string, ObjectFormData>>({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange'
  })
  const onAddButtonClickHandler = async () => {
    const isValid = await trigger()
    if (isValid) {
      setDefaultValues({ ...defaultValues, [uuid()]: generateFormDefaultValues() })
    }
  }
  const onBtnClickHandler = () => {
    trigger()
    onSubmit()
  }
  const onSubmit = handleSubmit(async (data) => {
    const { topForm, bottomFormMap, agreeThirdPartyProvision } = getItem('administrator') as PageFormData
    const bodyData = {
      application_id: uuid(),
      raw: {
        依頼元情報: formatTopFormData(topForm),
        システム管理者: Object.keys(bottomFormMap).map((key) => {
          return formatBottomFormData(bottomFormMap[key])
        }),
        第三者提供の確認: agreeThirdPartyProvision,
        物件情報: Object.keys(data).map((key) => {
          return formatObjectData(data[key])
        })
      }
    }
    debugger
    setLoading(true)

    try {
      const result = await createApplication(bodyData)
      if (result.status !== 200 && result.data.message !== 'ok') {
        return
      }
      window.gtag('event', 'submit', { data: JSON.stringify(bodyData) })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return
    }

    removeItem('agreement')
    removeItem('administrator')
    navigate('/complete')
  })
  const onDeleteButtonClickHandler = (key: string) => {
    unregister(key)
    const values: Record<string, ObjectFormData> = {}
    Object.entries(defaultValues).forEach(([k, v]) => {
      if (k !== key) {
        values[k] = v
      }
    })

    setDefaultValues(values)
  }

  const checkAddressDuplicate: (key: string) => Validate<string, Record<string, ObjectFormData>> = (key) => (val, formValues) => {
    const prefecture = getValues(`${key}.prefecture`)
    const cityTownVillage = getValues(`${key}.cityTownVillage`)
    for (const formKey in formValues) {
      if (formKey === key) continue
      if (formValues[formKey].prefecture === prefecture && formValues[formKey].cityTownVillage === cityTownVillage) {
        if (formValues[formKey].streetAddress === val) {
          return '物件の住所が重複しました'
        }
      }
    }
  }

  return <div className={style.container}>
    <div className="pabbit-lite">Pabbit Lite 無償設置キャンペーン</div>
    <h2>サービス利⽤申込</h2>
    <h3>物件登録</h3>
    <div className={style.content}>
      <form className={style.form} onSubmit={onSubmit} autoComplete="off">
        {
          Object.keys(defaultValues).map((key, index) => {
            return <div key={key} className={style.groupFormItem}>
              {index !== 0 && <Button className={style.deleteButton} type="danger" onClick={() => {
                onDeleteButtonClickHandler(key)
              }}>削除</Button>}
              <FormHeader
                title='物件情報'
                subtitle='本サービスを利用する物件の情報を下記にご記入下さい。なお、記入いただいた情報は申込情報の一部となります。'
              />
              <FormItem
                label='物件名称'
                formRegister={register(`${key}.objectName`, {
                  ...defaultInputValidator('物件名称を入力してください'),
                  validate: (value) => {
                    if (!value.trim()) {
                      return '物件名称を入力してください'
                    }
                    const values = getValues()
                    const objectsName = Object.entries(values).filter(([k]) => key !== k).map(([, v]) => v.objectName)
                    return objectsName.includes(value) ? '物件情報が重複しました' : undefined
                  }
                })}
                errorMessage={errors[key]?.objectName?.message}
              />
              <FormItem label="郵便番号" formRegister={register(`${key}.postalCode`, {
                validate: (value: string) =>
                  !!value.trim() || '郵便番号を入力してください',
                minLength: {
                  value: 6,
                  message: '郵便番号の形式が正しくありません'
                },
                maxLength: {
                  value: 7,
                  message: '郵便番号の形式が正しくありません'
                }
              })} errorMessage={errors[key]?.postalCode?.message} />
              <div className={style.formItemContainer}>
                <div className={style.formItem}>
                  <label>都道府県</label>
                  <select {...register(`${key}.prefecture`, { required: '都道府県を入力してください' })}>
                    <option value="" selected disabled hidden></option>
                    {prefecture.map((name) => {
                      return <option key={name} value={name}>{name}</option>
                    })}
                  </select>
                </div>
                {errors[key]?.prefecture?.message && <p className={style.errorMessage}>{errors[key]?.prefecture?.message}</p>}
              </div>
              <FormItem
                label='市区町村'
                formRegister={register(`${key}.cityTownVillage`, defaultInputValidator('市区町村を入力してください'))}
                errorMessage={errors[key]?.cityTownVillage?.message} />
              <FormItem
                label='町域・番地'
                formRegister={register(`${key}.streetAddress`, { ...defaultInputValidator('町域・番地を入力してください'), validate: checkAddressDuplicate(key) })}
                errorMessage={errors[key]?.streetAddress?.message} />
              <FormItem label='戸数'
                inputType='number'
                formRegister={
                  register(`${key}.numberOfUnits`, {
                    required: '戸数を入力してください',
                    min: {
                      value: 0,
                      message: '戸数の形式が正しくありません'
                    },
                    max: {
                      value: Number.MAX_SAFE_INTEGER,
                      message: '戸数の形式が正しくありません'
                    },
                    valueAsNumber: true
                  })}
                errorMessage={errors[key]?.numberOfUnits?.message} />
              <FormItem
                label={
                  <div>
                    <span>管理会社名</span> <br />
                    <sub className={style.subLabel}>（管理会社が無い場合は”無し”と記入）</sub>
                  </div>}
                formRegister={register(`${key}.managementCompanyName`, defaultInputValidator('管理会社名を入力してください'))}
                errorMessage={errors[key]?.managementCompanyName?.message} />
              <FormItem
                label={<div>
                  <span>管理会社 担当者名</span> <br />
                  <sub className={style.subLabel}>（管理会社が無い場合は”無し”と記入）</sub>
                </div>}
                formRegister={register(`${key}.managementCompanyNameOfContactPerson`, {
                  ...defaultInputValidator('管理会社 担当者名を入力してください')
                })}
                errorMessage={errors[key]?.managementCompanyNameOfContactPerson?.message} />
              <FormItem
                label={<div>
                  <span>管理会社 連絡先</span> <br />
                  <sub className={style.subLabel}>（管理会社が無い場合は”無し”と記入）</sub>
                </div>}
                formRegister={register(`${key}.managementCompanyContact`, defaultInputValidator('管理会社 連絡先を入力してください'))}
                errorMessage={errors[key]?.managementCompanyContact?.message} />
              <FormItem
                label='エントランスインターホン台数'
                inputType='number'
                formRegister={register(`${key}.numberOfAutoLockIntercomUnits`, {
                  required: 'エントランスインターホン台数を入力してください',
                  min: {
                    value: 0,
                    message: 'エントランスインターホン台数の形式が正しくありません'
                  },
                  max: {
                    value: Number.MAX_SAFE_INTEGER,
                    message: 'エントランスインターホン台数の形式が正しくありません'
                  },
                  valueAsNumber: true
                })}
                errorMessage={errors[key]?.numberOfAutoLockIntercomUnits?.message} />

              <div className={style.formItemContainer}>
                <div className={style.formItem}>
                  <label><span>宅配ボックス増設希望有無<sub>（有償、別途提案）</sub></span></label>
                  <select {...register(`${key}.wishToAddDeliveryBox`, { required: '宅配ボックス増設希望有無を入力してください' })}>
                    <option value="" label="" selected disabled hidden></option>
                    <option value="1" label="有">有</option>
                    <option value="0" label="無し">無し</option>
                  </select>
                </div>
                {errors[key]?.wishToAddDeliveryBox?.message && <p className={style.errorMessage}>{errors[key]?.wishToAddDeliveryBox?.message}</p>}
              </div>
            </div>
          })
        }
      </form>
      <div className={style.buttonsContainer}>
        <Button onClick={onAddButtonClickHandler} type="dark">物件情報の追加</Button>
      </div>
    </div>

    <div className={style.info}>現地調査・取付作業・運用可能サービスの詳細についてはメールでご確認させていただきます。</div>

    <div className={style.toolbar}>
      <Button className={style.button} loading={loading} onClick={onBtnClickHandler}>申し込み</Button>
    </div>

    <div className={style.pagination}>2 / 2</div>
  </div>
}
