import { type ReactNode, type FC } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

import style from './style.module.scss'

export interface FormItemProps {
  label: string | ReactNode
  formRegister?: UseFormRegisterReturn
  phoneNumberRegister?: [UseFormRegisterReturn, UseFormRegisterReturn, UseFormRegisterReturn]
  inputType?: 'input' | 'number' | 'phoneNumber' | 'date'
  errorMessage?: string
  placeholder?: string
  symbol?: ReactNode
}

export const FormItem: FC<FormItemProps> = ({
  label,
  formRegister,
  inputType = 'input',
  errorMessage,
  placeholder,
  phoneNumberRegister,
  symbol
}) => {
  return <div className={style.formItemContainer}>
    <div className={style.formItem}>
      <label>{label}{symbol}</label>
      {inputType !== 'phoneNumber' && <input {...formRegister} placeholder={placeholder} type={inputType} />}
      {inputType === 'phoneNumber' && <div className={style.phoneNumberContainer}>
        <input {...phoneNumberRegister?.[0]} /> -
        <input {...phoneNumberRegister?.[1]} /> -
        <input {...phoneNumberRegister?.[2]} />
      </div>}
    </div>
    {!!errorMessage && <div className={style.errorMessage}>{errorMessage}</div>}
  </div>
}
