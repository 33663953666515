import { type FC } from 'react'

import logoImage from '../../images/logo.jpg'
import style from './style.module.scss'

export interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  return <div className={style.header}>
    <img src={logoImage} alt="logo" />
  </div>
}
