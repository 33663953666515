import { type FC, useState, type UIEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { pdfjs } from 'react-pdf'

import { Button } from '../../components'
import style from './style.module.scss'
import { getSessionItem, setSessionItem } from '../../utils/localstorage'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// @ts-ignore
pdfjs.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

// 允许 PDF.js 范围请求`
// @ts-ignore
pdfjs.disableRange = false

// 禁止 PDF.js 预读取
// @ts-ignore
pdfjs.disableAutoFetch = true

export interface AgreementPageProps { }

export const AgreementPage: FC<AgreementPageProps> = () => {
  const navigate = useNavigate()
  const [isUserAgreed, setIsUserAgreed] = useState(getSessionItem('agreement')?.agreement)

  const onBtnClickHandler = () => {
    setSessionItem('agreement', { agreement: true })
    scrollTo(0, 0)
    navigate('/administrator')
  }

  const onAgreementScroll: UIEventHandler = (event) => {
    if (isUserAgreed) return
    const { clientHeight, scrollTop, scrollHeight } = event.currentTarget
    const isReachedBottom = Math.abs(clientHeight + scrollTop - scrollHeight) <= 1
    if (isReachedBottom) {
      setIsUserAgreed(true)
    }
  }

  return (
    <div className={style.container}>
      <div className="pabbit-lite">Pabbit Lite 無償設置キャンペーン</div>
      <h2>サービス利⽤申込</h2>
      <h3 style={{ marginBottom: 24 }}>利用規約</h3>
      <div className={style.content} onScroll={onAgreementScroll}>
        <h2>Pabbit Entrance(パビット エントランス)</h2>
        <h2>サービス利用規約</h2>
        <p><strong>1． 本利用規約の適用</strong></p>
        <ol className={style.bracketDecimal}>
          <li>本利用規約は、株式会社PacPortおよびアイホン株式会社（以下、「共同運営者」といいます。）が運営するエントランス解錠サービス（サービス名：Pabbit Entrance）（以下、「本サービス」といいます。）のご利用に関し、本利用規約第２３条に記載いただいた個人もしくは法人または団体であり、第２条４項に基づいて共同運営者により承諾された者（以下、「ご契約者様」といいます。）と共同運営者間の契約関係について定めるものです。</li>
          <li>共同運営者は、本サービスのご利用に関する条件につき、本利用規約によるほか、個別のルール、規約その他の諸規程（以下、総称して「個別規約」といいます。）により定めることができるものとします。個別規約は、本利用規約の一部を構成するものとし、本利用規約の内容と個別規約の内容が矛盾抵触する場合、個別規約の内容が優先して適用されるものとします。</li>
        </ol>
        <p><strong>2． お申込みについて</strong></p>
        <ol className={style.bracketDecimal}>
          <li>本サービスの利用を希望するお客様（以下、「利用希望者様」といいます。）は、本利用規約のすべてに同意した上で、共同運営者が定める手続きに従い、共同運営者が指定する情報（以下、「申込情報」といいます。）を提供することにより、本サービスの利用登録を行うものとします</li>
          <li>利用希望者様は、前項の利用登録手続において、自己の契約締結権限を有する者に、真正かつ最新の情報を登録させるものとします。なお、本サービスの利用登録が仮に契約締結権限を有さない者によりなされた場合でも、共同運営者は、これを利用希望者様の契約締結権限を有する者の意思によるものとみなすものとし、かかる本サービスの利用登録が第２条４項に基づいて共同運営者により承諾された場合、ご契約者様は本利用規約の定めに関して契約上の義務を負うものとします。</li>
          <li>利用希望者様またはご契約者様は、申込情報に変更が生じた場合、共同運営者が定める手続きに従い、速やかに申込情報の変更を行うものとします。申込内容が虚偽または最新でないことにより、共同運営者の利用希望者様またはご契約者様に対する通知が到達しなかった場合等、これにより利用希望者様またはご契約者様が生じた一切の損害について、共同運営者は何らの責任も負いません。</li>
          <li>共同運営者は、独自の基準に従って、利用希望者様の利用登録の可否を判断し、共同運営者が登録を認める場合、本サービスの利用に係る利用希望者様と共同運営者との契約（以下、「本利用契約」といいます。）は、共同運営者が利用希望者様に対し、所定の方法により利用登録を承諾する旨の通知を送信した時点で成立し、ご契約者様は本利用規約に従い、共同運営者が製造・販売する通信端末（以下、「本件機器」といいます。）が、ご契約者様が所有または管理する物件（以下、「本件物件」といいます。本件物件の対象は、本利用規約 別紙１に記載されている物件に限るものとします。）に設置された日または共同運営者が別途指定する日のいずれか遅い日（以下、「サービス利用開始日」といいます。）から、本サービスを利用することができるようになります。</li>
          <li>共同運営者は、前項その他本利用規約の規定にかかわらず、利用希望者様またはご契約者様が、次の各号のいずれかに該当する場合、利用登録を承諾しないことができます。
            <ol className={style.circleDecimal}>
              <li>虚偽の事実を登録した場合または申込情報に誤記もしくは漏れがあった場合</li>
              <li>過去において、本サービスまたは共同運営者が提供する他のサービスの利用に際して、規約違反、利用に関する登録の抹消、利用停止等の措置を受けたことがある場合</li>
              <li>競合調査を目的として本サービスを利用する意図を有していると共同運営者が判断した場合</li>
              <li>前各号のほか、共同運営者が利用希望者様またはご契約者様による利用登録を不適当であると合理的に判断した場合</li>
            </ol>
          </li>
        </ol>
        <p><strong>3． 本サービスの提供条件</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、本利用契約の有効期間中、ご契約者様に対し、本件物件において、本サービスを自ら利用し、または本件物件の居住者に使用させることのできる非独占的、譲渡不能、再許諾不能な権利を付与するものとします。ご契約者様が本件物件のディベロッパーである等、ご契約者様が本サービスを利用する者ではなく、本件物件の居住者に本サービスを使用させる場合、ご契約者様は、当該本件物件の管理組合その他共同運営者が指定する者をして、本利用規約の内容に同意させるものとします</li>
          <li>本サービスは、本件機器が設置されている本件物件に限り、提供されます。ご契約者様は、本サービスのご利用に際し、ご自身の責任と費用負担にて、本件機器を購入し、本件物件に設置し維持管理するものとします。なお、ご契約者様が本サービスのご利用端末として「Pabbit Lite」を選択した場合、ご契約者様は、本サービスの利用継続の条件として、Pabbit Liteの利用に必要な本件機器を、その耐用年数が経過する日までに交換する必要があることを理解し同意するものとします。</li>
          <li>ご契約者様は、本サービスのご利用に際し、ご自身の責任と費用負担にて、電気通信事業者等の電気通信サービスを利用して、本件機器をインターネットに接続するものとします。ただし、ご契約者様と共同運営者との間で別途の合意をした場合にはこの限りではありません。</li>
        </ol>
        <p><strong>4． 本利用規約の変更</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、いつでも、ご契約者様の事前の了承を得ることなく、本利用規約を変更することができるものとします。この場合、本サービスの提供条件は変更後の本利用規約によるものとします。</li>
          <li>本利用規約を変更する場合、共同運営者は、1ヶ月以上の予告期間をおいて、変更後の本利用規約の内容およびその効力発生日を共同運営者のホームページへの掲載その他適切な方法により周知するものとし、変更後の本利用規約は、当該効力発生日が到来した時点で効力を生じるものとします。また、これにかかわらず、ご契約者様が、本利用規約の変更内容について認識し得る状態で本サービスを利用した場合は、当該時点で変更後の本利用規約に異議なく同意したものとみなされます。</li>
        </ol>
        <p><strong>5． 免責事項</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、次の各号のいずれかの事由によりご契約者様、本件物件の居住者その他の第三者に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因の如何を問わず賠償の責任を負わないものとします。
            <ol className={style.circleDecimal}>
              <li>ご契約者様による本利用規約のいずれかの規定に違反する行為</li>
              <li>天災地変、騒乱、暴動、感染症の流行、法令の改正、労働争議その他共同運営者の合理的な制御を超える事由（以下、「不可抗力」といいます。）</li>
              <li>ご契約者様が本件機器を適切に維持または管理しなかったことに起因して発生した損害</li>
              <li>ご契約者様が契約した電気通信事業者等の電気通信サービスの不具合など、ご契約者様の通信環境の障害</li>
              <li>電気通信事業者の提供する電気通信役務の不具合に起因して発生した損害</li>
              <li>共同運営者が設置するコンピュータ、電気通信設備、電気通信回線、その他の機器およびソフトウェア（以下、「本サービス用設備」といいます。）のうち、共同運営者の製造に係らないソフトウェア、ハードウェア、機器等に起因して発生した損害</li>
              <li>善良なる管理者の注意をもってしても防御し得ない本サービス用設備へのコンピューターウイルスの侵入、不正アクセスまたはアタック</li>
              <li>運送事業者の責任による荷物の遅配、毀損、滅失または紛失</li>
              <li>その他共同運営者の責に帰すべからざる事由</li>
            </ol>
          </li>
          <li>共同運営者は、ご契約者様が本サービスを利用することによりご契約者様と第三者との間で生じた紛争等について一切責任を負わないものとします。</li>
        </ol>
        <p><strong>6． 一時停止または制限</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、次の各号のいずれかに該当する場合、ご契約者様の事前の通知または承諾を要することなく、本サービスの全部または一部の利用を一時的に中断、停止、または制限することができるものとします。
            <ol className={style.circleDecimal}>
              <li>本件機器若しくは本サービス用設備の故障、障害等、または本件機器若しくは本サービス用設備に対する第三者による妨害、侵入、情報改変等、やむを得ない事由によって本サービスを提供できない場合</li>
              <li>本件機器について保守点検作業又は交換作業が行われている場合</li>
              <li>定期的または緊急に本サービス用設備等の仕様の変更または保守点検を行う場合</li>
              <li>不可抗力により本サービスを提供できない場合</li>
              <li>運用上または技術上やむを得ない理由のある場合</li>
              <li>その他前各号に類する正当な理由がある場合</li>
            </ol>
          </li>
          <li>共同運営者は、ご契約者様に十分な予告期間をもって事前に通知することにより、本サービスの提供を一時的に中断、停止または制限することできるものとします。</li>
          <li>共同運営者は、ご契約者様が「７.（禁止事項）」各号に定める行為を行った場合、ご契約者様による本サービスのご利用を制限する場合があります。</li>
          <li>共同運営者は、前三項に定める事由のいずれかにより本サービスの提供を中断、停止、または制限したことに関して、ご契約者様またはその他の第三者が損害を被った場合であっても、一切責任を負わないものとします。</li>
        </ol>
        <p><strong>7． 禁止事項</strong></p>
        <ol className={style.bracketDecimal}>
          <li>ご契約者様は、本サービスのご利用に関し、次の各号のいずれの行為も行わないものとし、本件物件の居住者その他の第三者をして行わせてはならないものとします。
            <ol className={style.circleDecimal}>
              <li>法令、本利用規約もしくは公序良俗に違反する行為またはそのおそれのある行為</li>
              <li>共同運営者もしくは第三者の著作権、商標権等の知的財産権その他の権利もしくは利益を侵害する行為またはそのおそれのある行為</li>
              <li>本サービスの全部または一部を、本サービスの利用以外の目的での利用、複製、改変、公衆送信する行為</li>
              <li>本サービスのリバースエンジニアリング、逆コンパイル、逆アセンブルその他本サービスの構造を解析する行為</li>
              <li>本サービスを本件物件における本サービスの利用以外の目的で使用する行為</li>
              <li>本サービスに悪意のあるソフトウェア（コンピュータウイルス等）を含む情報を送信、書込みまたは掲載する行為</li>
              <li>第三者に成りすまして本サービスを利用する行為</li>
              <li>本サービスへの不正アクセス、ネットワークへの過負荷等の本サービスの運営を妨害するおそれのある行為</li>
              <li>その他、共同運営者が不適切、不適当と合理的に判断する行為</li>
            </ol>
          </li>
          <li>ご契約者様は、前項各号のいずれかに該当する行為がなされたことを知った場合、または該当する行為がなされるおそれがあると判断した場合、直ちに共同運営者に通知するものとします。</li>
          <li>共同運営者は、ご契約者様が第1項各号のいずれかの行為を行ったことによりご契約者様に生じた損害について賠償する責任を負いません。また、ご契約者様は、第1項各号のいずれかの行為を行ったことにより共同運営者に損害が生じた場合、当該損害を賠償するものとします。</li>
        </ol>
        <p><strong>8． 知的財産権</strong>
          <br />
          本サービスに関する著作権等を含む一切の知的財産権その他一切の権利は、共同運営者または共同運営者に対して使用許諾を行っている第三者に帰属するものとします。ご契約者様の本利用契約に基づく本サービスの利用は、本サービスに関する共同運営者または共同運営者に使用許諾を行っている者の知的財産権その他の権利の使用許諾を意味するものではありません。
        </p>
        <p><strong>9． 遅延損害金</strong></p>
        <ol className={style.bracketDecimal}>
          <li>ご契約者様は、本利用契約のいずれかの規定に違反したことで発生した損害賠償債務について、指定期日を過ぎてもなお履行しない場合には、指定期日の翌日から支払いの日までの日数について、年１４．６％の割合で計算して得た額を遅延損害金として共同運営者に支払うものとします。</li>
          <li>前項の支払いに必要な振込み手数料その他の費用は、ご契約者様が負担するものとします。</li>
        </ol>
        <p><strong>10． 個人情報の取扱い</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、本サービスを通じて収集したご契約者様および本件物件の居住者に関する個人情報については、共同運営者の各社の個人情報保護方針に基づき、適切に取り扱うものとします。
            <br />
            <span>　　株式会社PacPort：<a href="http://pacport.com/privacy/" target="_blank" rel="noreferrer">http://pacport.com/privacy/</a></span>
            <br />
            <span>　　アイホン株式会社： <a href="https://www.aiphone.co.jp/privacy/" target="_blank" rel="noreferrer">https://www.aiphone.co.jp/privacy/</a></span>
          </li>
          <li>本サービスは、本件物件の居住者に関する個人情報に該当し得る情報を取り扱う可能性があることから、ご契約者様は、本件物件の居住者に対し、共同運営者の求める合理的な方法に従って、共同運営者が本件物件の居住者の個人情報を収集すること、および当該個人情報を次条の定めに従って共同利用することを周知するものとします。</li>
        </ol>
        <p><strong>11． 個人情報の共同利用</strong>
          <br />
          <span>共同運営者は、本サービスを通じて収集した申込情報、ならびに本件物件の居住者による本サービスの利用に係る通信ログおよび動作ログを取得し、以下のとおり、共同利用いたします。</span>
        </p>
        <ol className={style.bracketDecimal}>
          <li>共同利用の目的
            <br />
            <span>&nbsp;&nbsp;&nbsp;本サービスの提供、管理、改善、ならびに営業活動</span>
          </li>
          <li>共同利用する者の範囲
            <ol className={style.circleDecimal}>
              <li>
                <span className="underline">株式会社PacPort</span>
                <br />
                <span>&nbsp;&nbsp;住所：〒100-0004</span>
                <br />
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '0 0 auto' }}>　　　&nbsp;&nbsp;</span>
                  <span>東京都千代田区大手町二丁目7番1号TOKIWABRIDGE 13F xTECH</span>
                </div>
                <span>&nbsp;&nbsp;TEL：03-6823-5058</span>
                <br />
                <span>&nbsp;&nbsp;代表者名：沈 燁</span>
              </li>
              <li>
                <span className="underline">アイホン株式会社</span>
                <br />
                <span>&nbsp;&nbsp;住所：〒460-0004</span>
                <br />
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '0 0 auto' }}>　　　&nbsp;&nbsp;</span>
                  <span>愛知県名古屋市中区新栄町一丁目1番&nbsp;&nbsp;明治安田生命名古屋ビル10階 </span>
                </div>
                <span>&nbsp;&nbsp;TEL：052-228-8181</span>
                <span>&nbsp;&nbsp;代表者名：鈴木&nbsp;&nbsp;富雄</span>
              </li>
            </ol>
          </li>
          <li>共同利用する情報の管理責任者
            <br />
            <span>&nbsp;&nbsp;株式会社PacPort（お問い合わせ先は、第２２条をご確認ください。）</span>
          </li>
        </ol>
        <p><strong>12． 秘密保持</strong></p>
        <ol className={style.bracketDecimal}>
          <li>ご契約者様は、本サービスの利用に関連して知得した共同運営者の技術上、営業上およびその他一切の情報（以下、「秘密情報」という。）について、共同運営者の事前の書面による承諾を得ることなく第三者に開示、漏洩せず、また本利用契約の履行または本サービスの利用以外の目的に利用してはならないものとします。</li>
          <li>前項の規定にかかわらず、次の各号の一に該当する情報は、秘密情報に含まれないものとします。
            <ol className={style.circleDecimal}>
              <li>知得した際、既に自己が所有していた情報</li>
              <li>知得した際、既に公知であった情報</li>
              <li>知得した後、自己の責めに帰せざる事由により公知となった情報</li>
              <li>正当な権限を有する第三者から秘密保持義務を負うことなく適法に入手した情報</li>
              <li>秘密情報によることなく、独自に開発・取得した情報</li>
            </ol>
          </li>
          <li>本条の義務は、本利用契約の終了後も3年間有効に存続するものとします。</li>
        </ol>
        <p><strong>13． 損害賠償の制限</strong>
          <br />
          <span style={{ marginLeft: 40, textIndent: 0, display: 'inline-block' }}>共同運営者の故意または重過失を理由とするものを除き、債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、本サービスまたは本利用契約に関し、共同運営者は、ご契約者様に対し何らの責任を負わないものとします。</span>
        </p>
        <p><strong>14． 有効期間</strong></p>
        <ol className={style.bracketDecimal}>
          <li>本利用契約は、サービス利用開始日以降、本利用規約の定めに従って解約又は解除されない限り、有効に存続するものとします。</li>
          <li>前項に関わらず、Pabbit Liteにかかる本利用契約の有効期間は、本サービス利用開始日から５年が経過する日又は本件機器に交換が必要な故障が生じた日のいずれか早い日までとなります。 Pabbit Liteをご利用のご契約者様が、本利用契約の有効期間満了日後も本サービスの利用の継続を希望する場合には、共同運営者が指定する方法により、新たに本サービスの利用申込を行うとともに、本件機器をご契約者様の費用で交換するものとします。</li>
        </ol>
        <p><strong>15． 中途解約</strong>
          <br />
          <span>本利用契約の有効期間満了前に、本サービスの利用停止を希望する場合、ご契約者様は、利用停止希望日の３０日前までに共同運営者が別途指定する方法にて、利用停止希望日とともに、利用停止の旨を通知するものとします。</span>
        </p>
        <p><strong>16． 解除等</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、ご契約者様が次の各号のいずれかに該当する場合、催告等を要せず直ちにご契約者様による本サービスの利用を停止または本利用契約の全部もしくは一部を解除することができるものとします。
            <ol className={style.circleDecimal}>
              <li>本利用規約のいずれかの規定に違反し、共同運営者の催告にもかかわらず、１４日以内に当該違反行為を是正しない場合</li>
              <li>申込情報に虚偽の事実があることが判明した場合</li>
              <li>支払不能もしくは支払停止の状態に陥ったとき、または自己振出の手形もしくは引き受けた手形もしくは小切手が不渡りとなったとき</li>
              <li>差押、仮差押、仮処分、強制執行もしくは競売の申立てがあったとき、または租税滞納処分を受けたとき</li>
              <li>資本金の額の減少、解散、会社分割、事業の全部もしくは一部の譲渡もしくは譲受け、株式移転または合併の決議をしたとき</li>
              <li>その他前各号に準じる事由がある場合</li>
            </ol>
          </li>
          <li>ご契約者様は、前項の各号のいずれかに該当した場合、ご契約者様の共同運営者に対する一切の金銭債務は期限の利益を失い、共同運営者に対して一切の金銭債務を直ちに弁済するものとします。</li>
          <li>本条の解除権の行使は、損害賠償の請求を妨げないものとします。</li>
        </ol>
        <p><strong>17． 本サービスの内容の変更、終了</strong></p>
        <ol className={style.bracketDecimal}>
          <li>共同運営者は、本サービスの内容を全部または一部を変更し、または提供を終了することができます。共同運営者は本サービスの全部を終了する場合、ご契約者様に対し、所定の方法で本サービス終了日の３ヶ月前までにその旨を通知します。</li>
          <li>共同運営者は、前項に基づき本サービスの内容を変更または終了したことに関して、ご契約者様またはその他の第三者が損害を被った場合であっても、一切責任を負わないものとします。</li>
        </ol>
        <p><strong>18． 反社会的勢力の排除</strong></p>
        <ol className={style.bracketDecimal}>
          <li>ご契約者様（法人その他団体の場合は、当該法人その他団体の役員等も含む）は、現在、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
            <ol className={style.circleDecimal}>
              <li>暴力団</li>
              <li>暴力団員</li>
              <li>暴力団準構成員</li>
              <li>暴力団関係企業</li>
              <li>総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等</li>
              <li>その他上記①～⑤に準ずる者</li>
            </ol>
          </li>
          <li>ご契約者様は、自らまたは第三者を利用して次の事項に該当する行為を行わないことを確約します。
            <ol className={style.circleDecimal}>
              <li>暴力的な要求行為</li>
              <li>法的な責任を超えた要求行為</li>
              <li>本利用契約に関して、脅迫的な言動をし、または暴力を用いる行為</li>
              <li>風説を流布し、偽計を用いまたは威力を用いて共同運営者のいずれかの信用を毀損し、または共同運営者のいずれかの業務を妨害する行為</li>
              <li>その他上記①～④に準ずる行為</li>
            </ol>
          </li>
          <li>共同運営者は、ご契約者様が前二項に違反した場合、何らの催告を要せずして、本利用契約を解除できるものとします。</li>
          <li>共同運営者は、本条に定める解除を行った場合であっても、ご契約者様に対する損害賠償請求権を失わないものとします。なお、ご契約者様は、かかる解除によって損害を被った場合であっても、共同運営者に対して損害賠償を請求することはできないものとします。</li>
        </ol>
        <p><strong>19． 譲渡</strong></p>
        <ol className={style.bracketDecimal}>
          <li>ご契約者様は、共同運営者の事前の書面による承諾がない限り、本利用契約上の地位を第三者に承継させ、または本利用契約に基づく権利もしくは義務を第三者に譲渡し、賃貸し、担保に供し、もしくはその他の処分をしてはならないものとします。ただし、ご契約者様が本件物件のディベロッパーである等、ご契約者様が本サービスを利用する者ではなく、本件物件の居住者に本サービスを使用させる場合、ご契約者様は、第２条３項に基づき、共同運営者に通知の上、当該本件物件の管理組合等に本サービスを利用する地位を譲渡できるものとします。</li>
          <li>共同運営者が本サービスにかかる事業を第三者に譲渡した場合、当該事業譲渡に伴い、本利用契約上の地位、本利用契約に基づく権利義務、ならびにご契約者の申込情報を含む個人情報を、当該事業譲渡の譲受人に譲渡することができるものとし、ご契約者様は、かかる譲渡につき本項において予め同意したものとします。本項に定める事業譲渡には、通常の事業譲渡に加え、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
        </ol>
        <p><strong>20． 準拠法および合意管轄</strong></p>
        <ol className={style.bracketDecimal}>
          <li>本利用規約および本利用契約の準拠法は、日本法とします。</li>
          <li>本利用規約および本利用契約に関連してご契約者様との間で紛争が生じた場合、誠意をもって協議するものとし、協議においても解決の見込みが立たない場合は、被告の本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>
        <p><strong>21． 残存条項</strong>
          <br />
          本利用契約の終了後も、第５条、第６条第４項、第７条第１項および第３項、第８条、第１３条、第１６条第２項および第３項、第１７条第２項、ならびに第１８条ないし第２２条は有効に存続し、また、第１２条は同条３項の定めに従って有効に存続するものとします。
        </p>
        <p><strong>22． お問い合わせ</strong>
          <br />
          <span>本サービスに関するご契約者様のご契約状況の照会その他本サービスに関するお問い合わせは、以下のお問い合わせ先までお問い合わせください。</span>
          <br />
        </p>
        <ul style={{ padding: 0 }}>
          <li>■お問い合わせ先
            <ol className={style.circleDecimal}>
              <li>
                <span className="underline">株式会社PacPort</span>
                <br />
                <span className="whitespace-break-spaces">{'　住所：〒100-0004'}</span>
                <br />
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '0 0 auto' }}>　　　　</span>
                  <span>東京都千代田区大手町二丁目7番1号TOKIWABRIDGE 13F xTECH</span>
                </div>
                <span>　WEB問合せ：<a href="https://pacport.co.jp/contact" target="_blank" rel="noreferrer">https://pacport.co.jp/contact</a> <sub>(※1)</sub></span>
                <br />
                <sub>　（※1）【営業時間】平日：10:00〜17:00（土曜・日曜・祝祭日・年末年始・会社都合による休日を除く）</sub>
              </li>
              <li>
                <span className="underline">アイホン株式会社</span>
                <br />
                <span>{'　住所：〒460-0004'}</span>
                <br />
                <div style={{ display: 'flex' }}>
                  <span style={{ flex: '0 0 auto' }}>　　　　</span>
                  <span>愛知県名古屋市中区新栄町一丁目1番明治安田生命名古屋ビル10階</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span>　</span>
                  <span>WEB問合せ：<a href="https://www.aiphone.co.jp/support/contact_products/" target="_blank" rel="noreferrer">https://www.aiphone.co.jp/support/contact_products/</a> <sub>(※2)</sub>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <span>　</span>
                  <sub>（※2）土・日・祝日など弊社休業日のお問い合わせは、翌営業日以降の回答となります。</sub>
                </div>
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div className={style.toolbar}>
        <Button disabled={!isUserAgreed} onClick={onBtnClickHandler}>利用規約に同意する</Button>
      </div>
    </div>
  )
}
