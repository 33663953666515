import { type RegisterOptions } from 'react-hook-form'

export const phoneValidator = (phone: string) => {
  if (!phone.trim()) {
    return '電話番号を入力してください'
  } else if (phone.length < 10) {
    return '電話番号の形式が正しくありません'
  } else {
    let pattern = /^\d{10,11}$/
    if (pattern.test(phone.trim())) {
      return undefined
    } else {
      pattern = /^(\d|-| ){10,13}$/
      if (pattern.test(phone.trim())) {
        return undefined
      }
      return '電話番号の形式が正しくありません'
    }
  }
}

export const defaultInputValidator = (message: string) => ({
  validate: (value: string) => !!value.trim() || message,
  maxLength: { value: 100, message: '100文字以内で入力してください' }
}) satisfies RegisterOptions
