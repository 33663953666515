const KEY = 'pabbit-cloud-'

export const setItem = (key: string, value: Record<string, unknown>) => {
  try {
    const valueString = JSON.stringify(value)
    localStorage.setItem(KEY + key, valueString)
  } catch (error) {}
}

export const getItem = (key: string) => {
  try {
    const valueString = localStorage.getItem(KEY + key) ?? ''
    return JSON.parse(valueString)
  } catch (error) {}
}

export const removeItem = (key: string) => {
  localStorage.removeItem(KEY + key)
}

export const getSessionItem = (key: string) => {
  try {
    const valueString = sessionStorage.getItem(KEY + key) ?? ''
    return JSON.parse(valueString)
  } catch (error) {}
}

export const setSessionItem = (key: string, value: Record<string, unknown>) => {
  try {
    const valueString = JSON.stringify(value)
    sessionStorage.setItem(KEY + key, valueString)
  } catch (error) {}
}
