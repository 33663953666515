import { type MouseEventHandler, type FC, type ReactNode } from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

export interface FormHeaderProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  className?: string
  onClick?: MouseEventHandler
}

export const FormHeader: FC<FormHeaderProps> = ({ title, subtitle, className, onClick }) => {
  return <div className={classNames(style.formHeader, className)} onClick={onClick}>
    {title}
    {typeof subtitle === 'string' ? <span>{subtitle}</span> : subtitle}
  </div>
}
