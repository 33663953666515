import { type FC } from 'react'

import { Button } from '../../components'
import style from './style.module.scss'

interface CompletePageProps {}

export const CompletePage: FC<CompletePageProps> = () => {
  return <div className={style.completePage}>
    <div>お申込みありがとうございました</div>
    <Button onClick={() => {
      window.open('about:blank', '_self')
    }}>閉じる</Button>
  </div>
}
