import axios from 'axios'

const HostUrl = process.env.REACT_APP_BASE_API_URL

const instance = axios.create({
  baseURL: HostUrl
})

export const createApplication = async (data: any) => {
  const url = '/application/'
  const result = await instance.post(url, data)

  return result
}
