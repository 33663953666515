import { type FC, useState, type ChangeEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { Document, pdfjs, Page } from 'react-pdf'

import style from './style.module.scss'
import { Button, FormItem, FormHeader } from '../../components'
import { getItem, getSessionItem, setItem } from '../../utils/localstorage'
import { phoneValidator, defaultInputValidator } from '../../utils/formValidator'

export interface AdministratorProps {}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface FormData {
  postalCode: string
  address: string
  nameOfCorporation: string
  responsiblePersonName: string
  titleHolderName: string
  departmentSignature: string
  phoneNumber: string
  emailAddress: string
  directlyByAiphone: string
  uuid?: string
}

export interface PageFormData {
  topForm: FormData
  bottomFormMap: Record<string, FormData>
  agreeThirdPartyProvision: boolean
}

const generateDefaultValues = () => {
  return {
    postalCode: '',
    address: '',
    nameOfCorporation: '',
    responsiblePersonName: '',
    titleHolderName: '',
    departmentSignature: '',
    phoneNumber: '',
    emailAddress: '',
    directlyByAiphone: '0'
  }
}

export const AdministratorPage: FC<AdministratorProps> = () => {
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    unregister,
    trigger,
    formState: { errors },
    getValues,
    setValue
  } = useForm<PageFormData>({
    mode: 'all'
  })
  const [agreeThirdPartyProvisionError, setAgreeThirdPartyProvisionError] =
    useState(false)
  const [agreeThirdPartyProvision, setAgreeThirdPartyProvision] = useState('')
  const [defaultValues, setDefaultValues] = useState<Record<string, FormData>>({
    [uuid()]: generateDefaultValues()
  })

  useEffect(() => {
    const { agreement } = getSessionItem('agreement') ?? {}

    if (!agreement) {
      navigate('/')
      return
    }
    const savedValues = getItem('administrator') as PageFormData & {
      agreeThirdPartyProvision: boolean
    }

    if (savedValues) {
      const { topForm, bottomFormMap, agreeThirdPartyProvision } = savedValues

      setDefaultValues(bottomFormMap)
      setAgreeThirdPartyProvision(
        agreeThirdPartyProvision ? 'agree' : 'notAgree'
      )
      setTimeout(() => {
        setValue('topForm', topForm)
        setValue('bottomFormMap', bottomFormMap)
      }, 100)
    }
  }, [])

  const onBtnClickHandler = () => {
    trigger()
    if (agreeThirdPartyProvision === '') {
      setAgreeThirdPartyProvisionError(true)
      return
    }
    onSubmit()
  }
  const onSubmit = handleSubmit((data) => {
    setItem('administrator', {
      ...data,
      agreeThirdPartyProvision: agreeThirdPartyProvision === 'agree'
    })
    navigate('/objects')
  })
  const onDeleteButtonClickHandler = (key: string) => {
    unregister(`bottomFormMap.${key}`)
    const values: Record<string, FormData> = {}
    Object.entries(defaultValues).forEach(([k, v]) => {
      if (k !== key) {
        values[k] = v
      }
    })

    setDefaultValues(values)
  }
  const onAgreementRadioChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAgreeThirdPartyProvisionError(false)
    setAgreeThirdPartyProvision(event.target.value)
  }
  const onCheckboxChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      return
    }

    const { topForm } = getValues()

    const [firstKey] = Object.keys(defaultValues)
    setValue(`bottomFormMap.${firstKey}`, { ...topForm })
  }

  const checkBottomFormDuplicate = (name: string, message: string) => (val: string) => {
    const bottomFormMap = getValues('bottomFormMap')
    let occurrence = 0
    for (const key in bottomFormMap) {
      if ((bottomFormMap[key] as any)[name] === val) {
        ++occurrence
        if (occurrence > 1) {
          return message
        }
      }
    }
  }

  return (
    <div className={style.container}>
      <div className="pabbit-lite">Pabbit Lite 無償設置キャンペーン</div>
      <h2>サービス利⽤申込</h2>
      <h3>物件管理者情報について</h3>
      <form className={style.content} autoComplete="off">
        <div className={style.form}>
          <FormHeader title="ご契約者様情報" />
          <FormItem
            label="郵便番号"
            symbol={<span className={style.postalCodeSign}>〒</span>}
            formRegister={register('topForm.postalCode', {
              validate: (value: string) =>
                !!value.trim() || '郵便番号を入力してください',
              minLength: {
                value: 6,
                message: '郵便番号の形式が正しくありません'
              },
              maxLength: {
                value: 7,
                message: '郵便番号の形式が正しくありません'
              }
            })}
            errorMessage={errors?.topForm?.postalCode?.message}
          />
          <FormItem
            label="住所"
            formRegister={register('topForm.address', defaultInputValidator('住所を入力してください'))}
            errorMessage={errors?.topForm?.address?.message}
          />
          <FormItem
            label="法人名・団体名・個人事業主名"
            formRegister={register('topForm.nameOfCorporation', defaultInputValidator('法人名・団体名・個人事業主名を入力してください'))}
            errorMessage={errors?.topForm?.nameOfCorporation?.message}
          />
          <FormItem
            label={
              <div>
                <span>責任者名</span> <br />
                <sub>（法人・団体の場合）</sub>
              </div>
            }
            placeholder="なしの場合、なしと記入してください"
            formRegister={register('topForm.responsiblePersonName', defaultInputValidator('責任者名を入力してください'))}
            errorMessage={errors?.topForm?.responsiblePersonName?.message}
          />
          <FormItem
            label={
              <div>
                <span>役職名</span> <br />
                <sub>（法人・団体の場合）</sub>
              </div>
            }
            placeholder="なしの場合、なしと記入してください"
            formRegister={register('topForm.titleHolderName', defaultInputValidator('役職名を入力してください'))}
            errorMessage={errors?.topForm?.titleHolderName?.message}
          />
          <FormItem
            label={
              <div>
                <span>部門部署名</span> <br />
                <sub>（法人・団体の場合）</sub>
              </div>
            }
            placeholder="なしの場合、なしと記入してください"
            formRegister={register('topForm.departmentSignature', defaultInputValidator('部門部署名を入力してください'))}
            errorMessage={errors?.topForm?.departmentSignature?.message}
          />
          <FormItem
            label="電話番号"
            formRegister={register('topForm.phoneNumber', { validate: phoneValidator })}
            errorMessage={errors?.topForm?.phoneNumber?.message}
          />
          <FormItem
            label="メールアドレス"
            formRegister={register('topForm.emailAddress', {
              required: 'メールアドレスを入力してください',
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'メールアドレスの形式が正しくありません'
              }
            })}
            errorMessage={errors?.topForm?.emailAddress?.message}
          />
          <div className={style.formItemContainer}>
            <div className={style.formItem}>
              <label>
                <span>アイホンから直接説明を受けた</span>
              </label>
              <select
                {...register('topForm.directlyByAiphone', {
                  validate: (value) => {
                    if (!value.trim()) {
                      return 'アイホンから直接説明を受けたを入力してください'
                    }
                  }
                })}
              >
                <option value="1" label="はい">
                  はい
                </option>
                <option value="0" label="いいえ">
                  いいえ
                </option>
              </select>
            </div>
            {errors?.topForm?.directlyByAiphone?.message && (
              <p className={style.errorMessage}>
                {errors?.topForm?.directlyByAiphone?.message}
              </p>
            )}
          </div>
        </div>
        <div className={style.pdfContainer}>
          <FormHeader
            className={style.pdfFormHeader}
            title="第三者提供の確認"
            subtitle="Amazon Key for Businessへのお申込みを同時にご希望の場合は、アマゾンジャパン合同会社への情報提供にご同意ください"
          />
          <Document
            renderMode="canvas"
            className={style.pdfDocument}
            file="/thirdPartyAgreement.pdf"
          >
            <Page
              loading="読み込み中..."
              renderTextLayer={false}
              pageIndex={0}
              width={Math.min(window.document.body.clientWidth - 16, 962)}
            ></Page>
          </Document>
          <form className={style.agreementForm} autoComplete="off">
            <div>
              <label>
                <input
                  onChange={onAgreementRadioChangeHandler}
                  type="radio"
                  value="agree"
                  name="agreement"
                  checked={agreeThirdPartyProvision === 'agree'}
                />
                第三者提供に同意する
              </label>
            </div>

            <div>
              <label>
                <input
                  onChange={onAgreementRadioChangeHandler}
                  type="radio"
                  value="notAgree"
                  name="agreement"
                  checked={agreeThirdPartyProvision === 'notAgree'}
                />
                第三者提供に同意しない
              </label>
            </div>
          </form>
          {agreeThirdPartyProvisionError && (
            <p className={style.errorMessage}>
              第三者提供の確認が未完了です 同意するかしないかを確認してください
            </p>
          )}
        </div>
        <div className={style.lastFormContainer}>
          <div className={style.toolbar}>
            <label>
              <input type="checkbox" onChange={onCheckboxChangeHandler} />
              ご契約者様情報と同じ
            </label>
            <p>
              チェックボックスを確認すると、以下の情報の入力は必要ありません
            </p>
          </div>

          <div>
            {Object.keys(defaultValues).map((key, index) => {
              return (
                <div key={key} className={style.groupFormItem}>
                  {index !== 0 && (
                    <Button
                      className={style.deleteButton}
                      type="danger"
                      onClick={() => {
                        onDeleteButtonClickHandler(key)
                      }}
                    >
                      削除
                    </Button>
                  )}
                  <FormHeader
                    title="Pabbit Deskの利用者情報"
                    className={style.formHeader}
                    subtitle={
                      <div className={style.subtitle}>
                        <div>
                          ”Pabbit Desk”は設置した“Pabbit
                          Lite”を管理するWebツールです。
                        </div>
                        <div>
                          Pabbit
                          Deskの利用に必要な情報を下記にご記入ください。なお、記入いただいた情報は申込情報の一部になります。
                        </div>
                      </div>
                    }
                  />
                  <FormItem
                    label="郵便番号"
                    symbol={<span className={style.postalCodeSign}>〒</span>}
                    formRegister={register(`bottomFormMap.${key}.postalCode`, {
                      validate: (value: string) =>
                        !!value.trim() || '郵便番号を入力してください',
                      minLength: {
                        value: 6,
                        message: '郵便番号の形式が正しくありません'
                      },
                      maxLength: {
                        value: 7,
                        message: '郵便番号の形式が正しくありません'
                      }
                    })}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.postalCode?.message
                    }
                  />
                  <FormItem
                    label="住所"
                    formRegister={register(`bottomFormMap.${key}.address`, defaultInputValidator('住所を入力してください'))}
                    errorMessage={errors.bottomFormMap?.[key]?.address?.message}
                  />
                  <FormItem
                    label="法人名・団体名・個人事業主名"
                    formRegister={register(
                      `bottomFormMap.${key}.nameOfCorporation`,
                      defaultInputValidator('法人名・団体名・個人事業主名を入力してください')
                    )}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.nameOfCorporation?.message
                    }
                  />
                  <FormItem
                    label="氏名"
                    formRegister={register(
                      `bottomFormMap.${key}.responsiblePersonName`,
                      {
                        ...defaultInputValidator('責任者名を入力してください'),
                        validate: (value: string) => {
                          if (!value.trim()) {
                            return '責任者名を入力してください'
                          }
                          return checkBottomFormDuplicate('responsiblePersonName', 'システム管理者が重複しました')(value)
                        }
                      }
                    )}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.responsiblePersonName
                        ?.message
                    }
                  />
                  <FormItem
                    label={
                      <div>
                        <span>部門部署名</span> <br />
                        <sub>（法人・団体の場合）</sub>
                      </div>
                    }
                    placeholder="なしの場合、なしと記入してください"
                    formRegister={register(
                      `bottomFormMap.${key}.departmentSignature`,
                      defaultInputValidator('部門部署名を入力してください')
                    )}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.departmentSignature?.message
                    }
                  />
                  <FormItem
                    label="電話番号"
                    formRegister={register(`bottomFormMap.${key}.phoneNumber`, { validate: phoneValidator })}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.phoneNumber?.message
                    }
                  />
                  <FormItem
                    label="メールアドレス"
                    formRegister={register(
                      `bottomFormMap.${key}.emailAddress`,
                      {
                        required: 'メールアドレスを入力してください',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'メールアドレスの形式が正しくありません'
                        }
                      }
                    )}
                    errorMessage={
                      errors.bottomFormMap?.[key]?.emailAddress?.message
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      </form>
      <div className={style.toolbar}>
        <Button
          onClick={async () => {
            const isValid = await trigger('bottomFormMap')
            if (isValid) {
              setDefaultValues({
                ...defaultValues,
                [uuid()]: generateDefaultValues()
              })
            }
          }}
          type="dark"
        >
          Pabbit Deskの利用者の追加
        </Button>
        <Button onClick={onBtnClickHandler} style={{ marginTop: 23 }}>
          &nbsp;&nbsp;&nbsp;&nbsp;次へ&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>
      </div>
      <div className={style.pagination}>1 / 2</div>
    </div>
  )
}
