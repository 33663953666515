import { BrowserRouter, Routes, Route } from 'react-router-dom'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './App.scss'
import { Header } from './components'
import { AgreementPage, AdministratorPage, ObjectsPage, CompletePage } from './pages'

function App () {
  return (
    <div className="App">
      <Header />
      <div className="content">
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/' element={<AgreementPage />} />
            <Route path="administrator" element={<AdministratorPage />} />
            <Route path="objects" element={<ObjectsPage />} />
            <Route path="complete" element={<CompletePage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}

export default App
